import { getStoreCode, showCusNotice } from './tool'
import { checkEmail, checkOrder } from '@/api/shop'
// 這邊是處理最後finish頁面的資訊
export const handlePromotions = async(email, is_use_member, trans_amount) => {
  if (getStoreCode() === 'richartlife' && trans_amount >= 1200) {
    try {
      const data = {
        email: email,
        is_use_member: is_use_member
      }
      const res = await checkEmail(data)
      if (res.data.data) return true
      else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  return true
}

export const ordFinpromotions = async data => {
  if (getStoreCode() === 'richartlife') {
    try {
      const res = await checkOrder({ order_num: data })
      if (res.data.data) {
        // 符合資格
        showCusNotice(
          '親愛的台新Pay客戶，您將啟程踏上元宇宙。\r\n在此為您獻上旅法插畫家林佾勳創作的「唬你糊塗 傻傻幸福 NFT !」 \r\n \r\n 本 NFT 領獎者將額外享有 3TET 商城提供的「3T NFT BOX」專屬優惠。',
          res.data.data,
          function() {}
        )
      } else {
        // 不符合不做事情
      }
    } catch (error) {
      console.log(error)
    }
  }
}
