<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <!-- <Promotionsbanner /> -->
    <!-- 確認訂單 -->
    <div v-if="ready" class="inter-content">
      <div class="orderinit-area container-fluid">
        <vsa-list>
          <vsa-item>
            <vsa-heading> 訂單明細 </vsa-heading>
            <vsa-content>
              <div class="order-area">
                <div class="box-group">
                  <div class="box-items">
                    <div class="box-th">
                      商品
                    </div>
                    <div class="box-td">
                      {{ productDetail.name }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      方案
                    </div>
                    <div class="box-td">
                      {{ orderItem.plan().plan_name
                      }}{{ orderItem.priceName() | pricename }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      價格
                    </div>
                    <div class="box-td">
                      {{ orderItem.pricePointLabel() }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      數量
                    </div>
                    <div class="box-td">
                      {{ orderItem.qty() }}
                    </div>
                  </div>
                </div>
              </div>
            </vsa-content>
          </vsa-item>
        </vsa-list>

        <div class="order-area">
          <div class="sub-title">
            <span>聯絡人資訊</span>
          </div>

          <div class="form-area mb-3">
            <div
              v-if="
                $store.getters.memberInfo &&
                  $store.getters.memberInfo.name &&
                  $store.getters.memberInfo.name !== ''
              "
              class="radiocheck check"
            >
              <input
                id="checkmember"
                type="checkbox"
                class="checkbox"
                name="checkmember"
                :checked="infoForm.is_use_member === 't'"
                @click="toggleSync()"
              >
              <label for="checkmember">同票夾聯絡人資料</label>
            </div>
            <div class="order-info-div">
              <input
                ref="bill_name"
                v-model="infoForm.bill_name"
                v-validate="'required|min:2'"
                name="bill_name"
                data-vv-as="付款人姓名"
                type="text"
                class="box"
                placeholder="付款人姓名"
                :disabled="
                  infoForm.is_use_member === 't' && infoForm.bill_name !== ''
                "
              >
              <div v-if="errors.has('bill_name')" class="help-block">
                {{ errors.first('bill_name') }}
              </div>
            </div>
            <div class="order-info-div">
              <input
                ref="bill_phone"
                v-model="infoForm.bill_phone"
                v-validate="'required|phone'"
                type="tel"
                class="box"
                name="bill_phone"
                data-vv-as="付款人手機"
                :placeholder="
                  '付款人手機 ' +
                    (type === 'point' && channel === 'happygo'
                      ? '(HappyGo手機號)'
                      : '')
                "
                :disabled="
                  infoForm.is_use_member === 't' && infoForm.bill_name !== ''
                "
              >
              <div v-if="errors.has('bill_phone')" class="help-block">
                {{ errors.first('bill_phone') }}
              </div>
            </div>
            <div class="order-info-div -">
              <input
                ref="bill_email"
                v-model="infoForm.bill_email"
                v-validate="'required|email'"
                type="email"
                class="box"
                name="bill_email"
                data-vv-as="付款人Email"
                placeholder="付款人Email"
                :disabled="
                  infoForm.is_use_member === 't' && infoForm.bill_name !== ''
                "
              >
              <div v-if="errors.has('bill_email')" class="help-block">
                {{ errors.first('bill_email') }}
              </div>
            </div>
            <div
              v-if="type === 'point' && channel === 'happygo'"
              class="order-info-div"
            >
              <input
                v-model="infoForm.id_no"
                v-validate="'required|min:4|max:4|integer'"
                type="text"
                class="box"
                name="id_no"
                data-vv-as="身分證後4碼"
                placeholder="身分證後4碼 (HappyGo身分證後四碼)"
              >
              <div v-if="errors.has('id_no')" class="help-block">
                {{ errors.first('id_no') }}
              </div>
            </div>
          </div>
        </div>
        <!-- 出行人資料 :訂單-->
        <div
          v-if="
            infoForm.touristprequest && infoForm.touristprequest.people.length
          "
          class="order-area"
        >
          <div class="sub-title">
            <span>訂單附加資料填寫</span>
          </div>
          <div class="p-0 ">
            <div
              v-for="(person, index) in infoForm.touristprequest.people"
              :key="index"
            >
              <div
                v-if="infoForm.touristprequest.people.length > 1"
                class="passengerlist"
                :class="{ topline: index > 0 }"
              >
                第{{ index + 1 }}位旅客
              </div>
              <div
                v-for="(request, i) in person.data"
                :key="i"
                class="mb-1 form-area"
              >
                <div
                  v-if="request.data_type === 'text' || request.data_type === '1'"
                  class="d-flex justify-content-center align-items-center row"
                  align-v="center"
                >
                  <div class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </div>
                  <input
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    type="text"
                    class="formbox col-12"
                    placeholder="請填寫"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'number' || request.data_type === '2'"
                  class="d-flex justify-content-center align-items-center row"
                >
                  <div class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </div>
                  <input
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}|numeric`"
                    type="text"
                    class="formbox col-12"
                    placeholder="請填寫"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'boolean' || request.data_type === '3'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </div>
                  <div class="col-5">
                    <input
                      :ref="request.question_code + index + i"
                      v-model="request.val"
                      v-validate="`${isrequired(request.is_required)}`"
                      type="radio"
                      :value="true"
                      class="checkbox "
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    >
                    <span> 是</span>
                  </div>
                  <div class="col-5">
                    <input
                      :ref="request.question_code + index + i"
                      v-model="request.val"
                      v-validate="`${isrequired(request.is_required)}`"
                      type="radio"
                      class="checkbox "
                      :value="false"
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    >
                    <span> 否</span>
                  </div>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'single' || request.data_type === '7'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class=" col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </label>
                  <b-form-select
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                    <b-form-select-option
                      v-for="option in request.data_option"
                      :key="option.value"
                      :value="option.value"
                      placeholder="請選擇"
                    >
                      {{ option.label }}
                    </b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'multiple' || request.data_type === '8'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <b-form-select
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12"
                    multiple
                    placeholder="請選擇"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                    <b-form-select-option
                      v-for="option in request.data_option"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'date' || request.data_type === '4'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <b-form-datepicker
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12 w-100 form-control"
                    placeholder="請選擇日期"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  />
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'time' || request.data_type === '5'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <b-form-timepicker
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12 w-100"
                    placeholder="請選擇時間"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  />
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'datetime' || request.data_type === '6'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <div class="col-12 p-0">
                    <b-form-datepicker
                      :ref="request.question_code + index + i"
                      v-model="request.val0"
                      v-validate="`${isrequired(request.is_required)}`"
                      class="col-12 w-100"
                      placeholder="請選擇日期"
                      :required="request.is_required"
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    />
                    <b-form-timepicker
                      :ref="request.question_code + index + i"
                      v-model="request.val1"
                      v-validate="`${isrequired(request.is_required)}`"
                      show-seconds
                      class="col-12 w-100 mt-2"
                      placeholder="請選擇時間"
                      :required="request.is_required"
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    />
                  </div>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 出行人資料 :旅客-->
        <div
          v-if="
            infoForm.bookingprequest && infoForm.bookingprequest.people.length
          "
          class="order-area"
        >
          <div class="sub-title">
            <span>旅客資料填寫</span>
          </div>
          <div class="p-0">
            <div
              v-for="(person, index) in infoForm.bookingprequest.people"
              :key="index"
            >
              <div
                v-if="infoForm.bookingprequest.people.length > 1"
                class="passengerlist"
                :class="{ topline: index > 0 }"
              >
                第{{ index + 1 }}位旅客
              </div>
              <div
                v-for="(request, i) in person.data"
                :key="i"
                class="mb-1 form-area"
              >
                <div
                  v-if="request.data_type === 'text' || request.data_type === '1'"
                  class="d-flex justify-content-center align-items-center row"
                  align-v="center"
                >
                  <div class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </div>
                  <input
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    type="text"
                    class="formbox col-12"
                    placeholder="請填寫"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'number' || request.data_type === '2'"
                  class="d-flex justify-content-center align-items-center row"
                >
                  <div class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </div>
                  <input
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}|numeric`"
                    type="text"
                    class="formbox col-12"
                    placeholder="請填寫"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'boolean' || request.data_type === '3'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </div>
                  <div class="col-15">
                    <input
                      :ref="request.question_code + index + i"
                      v-model="request.val"
                      v-validate="`${isrequired(request.is_required)}`"
                      type="radio"
                      :value="true"
                      class="checkbox "
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    >
                    <span> 是</span>
                  </div>
                  <div class="col-5">
                    <input
                      :ref="request.question_code + index + i"
                      v-model="request.val"
                      v-validate="`${isrequired(request.is_required)}`"
                      type="radio"
                      class="checkbox "
                      :value="false"
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    >
                    <span> 否</span>
                  </div>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'single' || request.data_type === '7'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>
                    {{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    />
                  </label>
                  <b-form-select
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                    <b-form-select-option
                      v-for="option in request.data_option"
                      :key="option.value"
                      :value="option.value"
                      placeholder="請選擇"
                    >
                      {{ option.label }}
                    </b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'multiple' || request.data_type === '8'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <b-form-select
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12"
                    multiple
                    placeholder="請選擇"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  >
                    <b-form-select-option
                      v-for="option in request.data_option"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'date' || request.data_type === '4'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <b-form-datepicker
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12 w-100 form-control"
                    placeholder="請選擇日期"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  />
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'time' || request.data_type === '5'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <b-form-timepicker
                    :ref="request.question_code + index + i"
                    v-model="request.val"
                    v-validate="`${isrequired(request.is_required)}`"
                    class="col-12 w-100"
                    placeholder="請選擇時間"
                    :data-vv-as="request.question_name"
                    :name="request.question_code + index + i"
                  />
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
                <div
                  v-else-if="request.data_type === 'datetime' || request.data_type === '6'"
                  class="d-flex justify-content-start align-items-center row"
                >
                  <label class="col-12 p-0">
                    <span v-if="request.is_required" style="color:red">*</span>{{ request.question_name }}
                    <b-icon
                      v-if="request.question_description"
                      v-b-tooltip.hover="request.question_description"
                      class="excicon"
                      icon="exclamation-circle-fill"
                      variant="secondary"
                    /></label>
                  <div class="col-12 p-0">
                    <b-form-datepicker
                      :ref="request.question_code + index + i"
                      v-model="request.val0"
                      v-validate="`${isrequired(request.is_required)}`"
                      class="col-12 w-100"
                      placeholder="請選擇日期"
                      :required="request.is_required"
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    />
                    <b-form-timepicker
                      :ref="request.question_code + index + i"
                      v-model="request.val1"
                      v-validate="`${isrequired(request.is_required)}`"
                      show-seconds
                      class="col-12 w-100 mt-2"
                      placeholder="請選擇時間"
                      :required="request.is_required"
                      :data-vv-as="request.question_name"
                      :name="request.question_code + index + i"
                    />
                  </div>
                  <div
                    v-if="errors.has(request.question_code + index + i)"
                    class="help-block col-12 passengewarn"
                  >
                    {{ errors.first(request.question_code + index + i) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 使用優惠券 -->
        <div class="order-area">
          <div class="sub-title">
            <span>使用優惠券</span>
          </div>
          <div class="form-area">
            <div class="d-flex">
              <b-col cols="8" style="padding:0">
                <input
                  ref="discount_num"
                  v-model="infoForm.discount_num"
                  type="text"
                  class="box"
                  placeholder="請輸入優惠券並點擊檢查"
                  @change="handlediscountnum(infoForm.discount_num)"
                >
              </b-col>
              <b-col cols="4">
                <button class="btn btn-next" @click="handleverify">
                  檢查
                </button>
              </b-col>
            </div>
          </div>
        </div>
        <!-- 使用好友序號 -->
        <div v-if="channel === 'richartlife'" class="order-area">
          <div class="sub-title">
            <span>好友推薦序號</span>
          </div>
          <div class="form-area">
            <div class="d-flex">
              <b-col cols="8" style="padding:0">
                <input
                  ref="share_code"
                  v-model="infoForm.share_code"
                  v-validate="'max:10'"
                  type="text"
                  class="box"
                  name="share_code"
                  data-vv-as="好友推薦序號"
                  placeholder="請輸入好友推薦序號"
                >
                <div v-if="errors.has('share_code')" class="help-block">
                  {{ errors.first('share_code') }}
                </div>
              </b-col>
            </div>
          </div>
        </div>

        <div class="order-area">
          <div class="sub-title">
            <span>付款方式</span>
          </div>
          <div class="form-area">
            <div v-for="(pay, index) of payData" :key="index">
              <div class="radiocheck mb-2">
                <input
                  :id="'pay' + index"
                  v-model="infoForm.paytype_id"
                  type="radio"
                  class="radio"
                  name="pay"
                  :value="pay.paytype_id"
                >
                <label :for="'pay' + index">{{ pay.paytype_name_web }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="order-area">
          <div class="sub-title">
            <span>發票種類</span>
          </div>
          <p class="text-center">
            <small class="ml-2">
              (三聯式發票開立後無法更換為二聯式發票)
            </small>
          </p>
          <div class="form-area">
            <div v-if="isnoinv_type" class="radiocheck mb-2">
              <input
                id="radio0"
                v-model="infoForm.inv_type"
                type="radio"
                class="radio"
                name="radio0"
                value="0"
              >
              <label for="radio0">免發票</label>
            </div>

            <div v-if="!isnoinv_type" class="radiocheck mb-2">
              <input
                id="radio2"
                v-model="infoForm.inv_type"
                type="radio"
                class="radio"
                name="radio3"
                value="1"
              >
              <label for="radio2">電子發票二聯</label>
            </div>

            <div v-if="!isnoinv_type" class="radiocheck clicker">
              <input
                id="radio3"
                v-model="infoForm.inv_type"
                type="radio"
                class="radio"
                name="radio3"
                value="3"
              >
              <label for="radio3">電子發票三聯</label>
            </div>

            <div v-if="infoForm.inv_type === '3'" class="bill">
              <input
                v-model="infoForm.inv_uni_title"
                v-validate="'required'"
                type="text"
                class="box"
                placeholder="發票抬頭"
                name="inv_uni_title"
                data-vv-as="發票抬頭"
              >
              <div v-if="errors.has('inv_uni_title')">
                <div class="help-block">
                  {{ errors.first('inv_uni_title') }}
                </div>
              </div>
              <input
                v-model="infoForm.inv_uni_num"
                v-validate="'required|min:8|max:8|integer'"
                type="text"
                class="box"
                placeholder="發票統編"
                name="inv_uni_num"
                data-vv-as="發票統編"
              >
              <div v-if="errors.has('inv_uni_num')">
                <div class="help-block">
                  {{ errors.first('inv_uni_num') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="order-area">
          <div class="sub-title">
            <span>訂單備註</span>
          </div>
          <div class="form-area small-mobile">
            <textarea
              v-model="infoForm.remark"
              v-validate="'max:255'"
              data-vv-as="訂單備註"
              name="remark"
              rows="3"
              class="form-control mb-3"
            />
            <div v-if="errors.has('remark')">
              <div class="help-block">
                {{ errors.first('remark') }}
              </div>
            </div>
            <div class="radiocheck check checkcredicbarea">
              <input
                id="checkcredicb"
                v-model="checkedRule"
                type="checkbox"
                class="checkbox"
                name="checkcredicb"
              >
              <label
                for="checkcredicb"
              ><span>我已閱讀</span>
                <b-link v-b-modal.modal-scrollable-1>{{
                  shop.cancel_name
                }}</b-link>
                <span>與</span>
                <b-link v-b-modal.modal-scrollable-2>{{
                  shop.personal_name
                }}</b-link>
                <span>，並同意取消訂單時，由商城代為處理發票及銷貨退回證明單。</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ready" class="price-items-fix">
      <div v-if="orderItem.type() === 'point'" class="info-box">
        <span>訂單金額</span>
        <em><big>{{ orderItem.pointTotalPoint() }}</big>點＋NT$<big>{{ orderItem.pointTotalPrice() | priceFormat }}</big></em>
      </div>
      <div v-else class="info-box">
        <span>訂單金額</span>
        <em v-if="isuseticket">
          <span
            style="	text-decoration:line-through;"
          >NT$<big>
            {{ orderItem.creditTotalPrice() | priceFormat }}</big></span>
          NT$<big> {{ tickeyTotalprice | priceFormat }}</big></em>
        <em
          v-else
        >NT$<big> {{ orderItem.creditTotalPrice() | priceFormat }}</big></em>
      </div>
      <button class="btn btn-next" type="button" @click="doPayment()">
        下一步
      </button>
    </div>
    <form
      ref="paymentForm"
      :action="paymentInfo.endPoint"
      method="POST"
      style="display: none;"
    >
      <input
        v-for="(val, key) in paymentInfo"
        :key="key"
        :name="key"
        :value="val"
        type="hidden"
      >
    </form>
    <div v-if="$store.getters.shopInfo">
      <b-modal id="modal-scrollable-1" scrollable :title="shop.cancel_name">
        <div class="main py-3">
          <dl>
            <dt>{{ shop.cancel_name }}</dt>
            <dd class="nl2br" v-text="$store.getters.shopInfo.cancel_policy" />
          </dl>
        </div>
        <template #modal-footer="{ hide }">
          <b-button size="sm" variant="outline-secondary" @click="hide('')">
            close
          </b-button>
        </template>
      </b-modal>

      <b-modal id="modal-scrollable-2" scrollable :title="shop.personal_name">
        <div class="main py-3">
          <dl>
            <dt>{{ shop.personal_name }}</dt>
            <dd
              class="nl2br"
              v-text="$store.getters.shopInfo.personal_policy"
            />
          </dl>
          <dl>
            <dt>{{ shop.channel_name }}</dt>
            <dd class="nl2br" v-text="$store.getters.shopInfo.channel_policy" />
          </dl>
        </div>

        <template #modal-footer="{ hide }">
          <b-button size="sm" variant="outline-secondary" @click="hide('')">
            close
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import Promotionsbanner from '@/components/Promotionsbanner'
import HeaderInter from '@/components/HeaderInter'
import {
  showNotice,
  getStoreCode,
  checkPlatform,
  getDateFormat
} from '@/utils/tool'
import { addStep1 } from '@/api/payment'
import invoiceDonate from '@/assets/data/invoice_donate.json'
import { getItem, getPaytype, checkNum } from '@/api/shop'
import { getMember } from '@/api/user'
import orderItem from '@/utils/orderItem'
import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion'
import { handlePromotions } from '@/utils/promotions'
import CryptoJS from 'crypto-js'
import store from '../../store'

export default {
  name: 'OrderUserInfo',
  components: {
    HeaderInter,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
  },
  data() {
    const price = this.$store.getters.orderItem.plans[0].prices[0]
    const qty = price.qty
    return {
      channel: getStoreCode(),
      ready: false,
      orderItem: orderItem,
      productDetail: false,
      invoiceDonate: invoiceDonate,
      checkedRule: false,
      userData: {},
      isnoinv_type: false, // 免發票
      infoForm: {
        partner: this.$cookies.get('partner_code'),
        trans_point: price.point ? price.point * qty : 0,
        trans_amount: price.sale_price ? price.sale_price * qty : 0,
        paytype_id: 1,
        mobile: null,
        id_no: null, // 欄位
        bill_name: null, // 欄位
        bill_phone: null, // 欄位
        bill_email: null, // 欄位
        inv_type: '1', // 欄位 //發票種類,0 免發票 1 電子發票二聯 2 二聯紙本 3 電子發票三聯 4 捐贈
        inv_uni_num: null, // 欄位
        inv_uni_title: null, // 欄位
        remark: '',
        is_use_member: 'f',
        inv_donate: null, // 欄位 1106
        discount_num: '', // 優惠券代碼
        share_code: '', // 好友代碼
        productrequest: [], // 出行人資訊
        touristprequest: { fqa: [], people: [] },
        bookingprequest: { fqa: [], people: [] }
      },
      paymentInfo: {
        endPoint: null,
        MerchantID: null,
        TradeInfo: null,
        TradeSha: null,
        Version: 1.6
      },
      payData: [], // 付款方式資料
      shop: {
        cancel_name: this.$store.getters.shopInfo.cancel_name,
        personal_name: this.$store.getters.shopInfo.personal_name,
        channel_name: this.$store.getters.shopInfo.channel_name
      },
      isuseticket: false,
      tickeyTotalprice: 0,
      opappordernum: null,
      opKey: 'L@CA#z5xUZpx6BTwubp9C9Q^3Lr96&Xn',
      opIV: 'yK^5N5a&VCjXVrfE',
      bind_paytype: []
    }
  },
  computed: {
    type: function() {
      return this.item.plans[0].prices[0].point_id ? 'point' : 'credit'
    },
    item: function() {
      return this.$store.getters.orderItem
    },
    qty: function() {
      return this.item.plans[0].prices[0].qty
    }
  },
  created() {
    // 註冊opapp錢包
    window.OPGetWalletStatusV2Result = this.OPGetWalletStatusV2Result.bind(this)
    window.OPOnlinePaymentResult = this.OPOnlinePaymentResult.bind(this)
    if (!this.item || !this.item.item_id) {
      const self = this
      showNotice('請選擇方案、數量', function() {
        self.$router.push({ path: '/product' }).catch(() => {})
      })
    } else {
      getItem(this.item.item_id, { get_bind_paytype: 't' }).then(response => {
        this.productDetail = response.data.data
        // 商品限定金流
        if (response.data.data.bind_paytype.length) {
          this.bind_paytype = response.data.data.bind_paytype
        }
        this.orderItem.init(
          this.$store.getters.orderItem,
          this.productDetail,
          '/product'
        )
        // 出行人資料部分  根據is_everyone 產生對應筆數 touristprequest bookingprequest
        if (this.productDetail.plans && this.productDetail.plans.length) {
          if (
            this.productDetail.plans[0].product_request &&
            this.productDetail.plans[0].product_request.length
          ) {
            this.productDetail.plans[0].product_request.forEach(ee => {
              if (ee.data_type === 'multiple' || ee.data_type === '8') {
                ee.val = []
              } else if (ee.data_type === 'datetime' || ee.data_type === '6') {
                ee.val0 = ''
                ee.val1 = ''
              } else ee.val = null

              if (ee.question_type === 'booking' || ee.question_type === '2') {
                this.infoForm.touristprequest.fqa.push(ee)
              } else if (ee.question_type === 'tourist' || ee.question_type === '1') {
                this.infoForm.bookingprequest.fqa.push(ee)
              }
            })
            if (this.infoForm.touristprequest.fqa.length) {
              const iseveryone = this.infoForm.touristprequest.fqa.find(e => {
                return e.is_everyone === true
              })
              if (iseveryone) {
                for (let index = 0; index < this.orderItem.qty(); index++) {
                  if (index === 0) {
                    this.infoForm.touristprequest.people.push({
                      data: JSON.parse(
                        JSON.stringify(this.infoForm.touristprequest.fqa)
                      )
                    })
                  } else {
                    const reqdata = []
                    this.infoForm.touristprequest.fqa.forEach(e1 => {
                      if (e1.is_everyone) {
                        reqdata.push(JSON.parse(JSON.stringify(e1)))
                      }
                    })
                    this.infoForm.touristprequest.people.push({ data: reqdata })
                  }
                }
              } else {
                this.infoForm.touristprequest.people.push({
                  data: JSON.parse(
                    JSON.stringify(this.infoForm.touristprequest.fqa)
                  )
                })
              }
            }
            if (this.infoForm.bookingprequest.fqa.length) {
              const iseveryone = this.infoForm.bookingprequest.fqa.find(e => {
                return e.is_everyone === true
              })
              if (iseveryone) {
                for (let index = 0; index < this.orderItem.qty(); index++) {
                  if (index === 0) {
                    this.infoForm.bookingprequest.people.push({
                      data: JSON.parse(
                        JSON.stringify(this.infoForm.bookingprequest.fqa)
                      )
                    })
                  } else {
                    const reqdata = []
                    this.infoForm.bookingprequest.fqa.forEach(e1 => {
                      if (e1.is_everyone) {
                        reqdata.push(JSON.parse(JSON.stringify(e1)))
                      }
                    })
                    this.infoForm.bookingprequest.people.push({ data: reqdata })
                  }
                }
              } else {
                this.infoForm.bookingprequest.people.push({
                  data: JSON.parse(
                    JSON.stringify(this.infoForm.bookingprequest.fqa)
                  )
                })
              }
            }
          }
        }

        // 取得付款方式資料 若是付費金額為0元的點數商品 若不是則呼叫call api getPaytype
        // 點數支付且價格為0
        if (
          (this.orderItem.type() === 'credit' &&
            this.orderItem.creditTotalPrice() === 0) ||
          (this.orderItem.type() === 'point' &&
            this.orderItem.pointTotalPrice() === 0)
        ) {
          this.isnoinv_type = true
          this.infoForm.inv_type = 0
          // if only point and price = 0 set the payment type = -1
          const freepay = [
            {
              paytype_id: -1,
              paytype_name_web: '免付費',
              payment: ''
            }
          ]
          this.infoForm.paytype_id = -1
          this.payData = freepay
          this.getMemberInfo()
          this.ready = true
        } else {
          getPaytype().then(response => {
            if (response.data.data.length) {
              // 信託付款的產品找出信託付款的選項 若是付款方式不符合信託trustor errormsg => 回產品清單頁面
              if (this.orderItem.productDetail.trustor_code) {
                this.payData = response.data.data.filter(e => {
                  if (
                    e.trustor_code === this.orderItem.productDetail.trustor_code
                  ) {
                    return e
                  }
                })
                if (!this.payData.length) {
                  showNotice('產品資訊錯誤, 請選擇其他商品!', () => {
                    this.$router.push({ path: '/product/cate/0' })
                  })
                }
              } else {
                // 正常的付款選項先濾掉信託的支付
                this.payData = response.data.data.filter(e => {
                  if (e.trustor_code === null) {
                    return e
                  }
                })
                // 若OPAPP 濾掉icash
                if (
                  this.$store.getters.isinapp &&
                  this.$store.getters.isinapp.name === 'opapp'
                ) {
                  this.payData = this.payData.filter(e => {
                    if (e.payment !== 'icash') {
                      return e
                    }
                  })
                } else if (
                  this.$store.getters.isinapp &&
                  this.$store.getters.isinapp.name === 'icash'
                ) {
                  // 若 icash  濾掉 cathaybk 國泰聚合
                  this.payData = this.payData.filter(e => {
                    if (e.payment !== 'cathaybk') {
                      return e
                    }
                  })
                }
                // 假如指定支付

                const tmppayarray = []
                if (this.bind_paytype.length) {
                  this.payData.forEach(e => {
                    const isfind = this.bind_paytype.find(e1 => {
                      return (
                        e1.paytype_id.toString() === e.paytype_id.toString()
                      )
                    })
                    if (isfind) tmppayarray.push(e)
                  })
                  this.payData = tmppayarray
                  if (this.payData.length) {
                    this.infoForm.paytype_id = this.payData[0].paytype_id
                  } else {
                    showNotice('該商品指定支付金流目前尚未開啟, 請先選擇其他商品!', () => {
                      this.$router.push({ path: '/product/cate/0' })
                    })
                  }
                }
              }

              if (this.payData[0].payment === 'icash') {
                this.infoForm.paytype_id = 5
              }
              if (this.payData.length) {
                this.infoForm.paytype_id = this.payData[0].paytype_id
              }
              // 取會員資料
              this.getMemberInfo()
              this.ready = true
            }
          })
        }
      })
    }
  },
  methods: {
    isrequired(status) {
      if (status) {
        return 'required'
      } else return ''
    },
    OPOnlinePaymentResult(data, payment_status) {
      if (payment_status) {
        this.$router.push({ path: '/order/checkPaid?id=' + this.opappordernum })
      }
    },
    OPGetWalletStatusV2Result(
      wallet_status,
      is_opw,
      is_icashpay,
      opwcard_issuer_list
    ) {
      // 當確認成功
      if (wallet_status === 'Y' || is_opw === 'Y' || is_icashpay === 'Y') {
        const data = {
          datetime: getDateFormat(new Date()) + '', // 時間
          merchant_trade_no: this.opappordernum + '', // 訂單編號
          terminal_id: '83501114', // 統編
          merchant_data: '', // 商家端資料
          icp_merchant_id: process.env.VUE_APP_ICP_MERCHANT_ID // 無帶空直
        }
        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(data),
          CryptoJS.enc.Utf8.parse(this.opKey),
          {
            iv: CryptoJS.enc.Utf8.parse(this.opIV),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
        )
        const args = {
          source: '3TW',
          data: encrypted.toString()
        }
        if (encrypted.toString()) {
          window.OPAPPJsInterface.call('OPOnlinePayment', args)
        }
      } else {
        showNotice(
          '無綁定任何支付，請先回到APP綁定您的支付功能!',
          function() {}
        )
      }
    },
    handlediscountnum(val) {
      if (this.isuseticket) {
        this.isuseticket = false
      }
    },
    async handleverify() {
      try {
        const requestdata = {
          num: this.infoForm.discount_num,
          items: []
        }
        requestdata.items.push(this.item)
        const res = await checkNum(requestdata)
        if (res.data.succ && res.data.data) {
          this.isuseticket = true
          this.tickeyTotalprice =
            parseInt(this.orderItem.creditTotalPrice()) -
            parseInt(res.data.data)
          showNotice('優惠券可使用', () => {})
        } else {
          this.isuseticket = false
          showNotice(
            '此優惠券序號無效，請確認輸入的序號是否正確。 \r\n\r\n 優惠券序號無效的可能原因： \r\n - 輸入錯誤的優惠券序號 \r\n - 優惠券序號已使用 \r\n - 優惠券序號已逾期 \r\n - 訂單商品或金額未符合使用規定 \r\n - 非原先取得優惠券序號之帳號登入使用',
            () => {}
          )
        }
      } catch (error) {
        this.isuseticket = false
        console.log(error)
      }
    },
    toggleSync() {
      if (this.infoForm.is_use_member === 't') {
        this.infoForm.is_use_member = 'f'

        this.infoForm.bill_phone = ''
        this.infoForm.bill_email = ''
        this.infoForm.bill_name = ''
      } else {
        this.infoForm.is_use_member = 't'
        const phone = this.$store.getters.memberInfo.phone || ''
        this.infoForm.bill_phone = phone
        const email = this.$store.getters.memberInfo.email || ''
        this.infoForm.bill_email = email
        const name = this.$store.getters.memberInfo.name || ''
        this.infoForm.bill_name = name
      }
    },
    getMemberInfo(callback) {
      // 取會員資料
      getMember()
        .then(response => {
          if (response.data.data.member_id !== undefined) {
            this.$store.commit('SET_MEMBER_INFO', response.data.data)
            // 若會員以輸入資料 自動帶入資料
            if (response.data.data.email) this.toggleSync()
          }
          if (callback !== undefined) {
            callback()
          }
        })
        .catch(() => {})
    },
    getPay(paytype_id) {
      return this.payData.filter(pay => pay.paytype_id === paytype_id).pop()
    },
    async doPayment() {
      this.$validator.validateAll().then(async valid => {
        if (valid) {
          if (!this.checkedRule) {
            showNotice(`請勾選同意以下說明：「我己閱讀${this.shop.cancel_name}與${this.shop.personal_name}，並同意取消訂單時，由商城代為處理發票及銷貨退回證明單。」`)
          } else {
            this.infoForm.platform = checkPlatform()
            this.infoForm.mobile = this.infoForm.bill_phone
            this.$store.commit('SET_INFO', this.infoForm)
            const order = this.infoForm
            order.items = []
            order.items.push(this.item)
            this.$store.commit('SET_LOADING', true)
            const self = this
            // 出行人問題組合-訂單+旅客  bookingprequest touristprequest-->
            if (
              this.infoForm.touristprequest.people.length ||
              this.infoForm.bookingprequest.people.length
            ) {
              const tmparray = []
              for (let i = 0; i < this.orderItem.qty(); i++) {
                const ele = { isexist: false }
                if (this.infoForm.touristprequest.people.length > i) {
                  ele.isexist = true
                  this.infoForm.touristprequest.people[i].data.forEach(e1 => {
                    if (e1.data_type === 'datetime' || e1.data_type === '6') {
                      if (e1.val0 && !e1.val1) {
                        e1.val1 = '00:00:00'
                      }
                      ele[e1.question_code] = e1.val0
                        ? [`${e1.val0} ${e1.val1}`]
                        : []
                    } else if (e1.data_type === 'time' || e1.data_type === '5') {
                      ele[e1.question_code] = e1.val
                        ? [e1.val.substring(0, 5)]
                        : []
                    } else if (!Array.isArray(e1.val)) {
                      ele[e1.question_code] = e1.val ? [e1.val] : []
                    } else {
                      ele[e1.question_code] = e1.val
                    }
                  })
                }
                if (this.infoForm.bookingprequest.people.length > i) {
                  ele.isexist = true
                  this.infoForm.bookingprequest.people[i].data.forEach(e1 => {
                    if (e1.data_type === 'datetime' || e1.data_type === '6') {
                      if (e1.val0 && !e1.val1) {
                        e1.val1 = '00:00:00'
                      }
                      ele[e1.question_code] = e1.val0
                        ? [`${e1.val0} ${e1.val1}`]
                        : []
                    } else if (e1.data_type === 'time' || e1.data_type === '5') {
                      ele[e1.question_code] = e1.val
                        ? [e1.val.substring(0, 5)]
                        : []
                    } else if (!Array.isArray(e1.val)) {
                      ele[e1.question_code] = e1.val ? [e1.val] : []
                    } else {
                      ele[e1.question_code] = e1.val
                    }
                  })
                }
                delete ele.isexist
                tmparray.push(ele)
              }
              this.infoForm.items[0].plans[0].prices[0].product_request = tmparray
            }
            // 出行人問題組合 <---
            if (this.infoForm.discount_num !== '') {
              const requestdata = {
                num: this.infoForm.discount_num,
                items: []
              }
              requestdata.items.push(this.item)
              const checkNumres = await checkNum(requestdata)
              if (checkNumres.data.succ && checkNumres.data.data) {
                this.isuseticket = true
                order.finalamount =
                  parseInt(order.trans_amount) - parseInt(checkNumres.data.data)
              } else {
                this.$store.commit('SET_LOADING', false)
                showNotice(
                  '此優惠券序號無效，請確認輸入的序號是否正確。 \r\n\r\n 優惠券序號無效的可能原因： \r\n - 輸入錯誤的優惠券序號 \r\n - 優惠券序號已使用 \r\n - 優惠券序號已逾期 \r\n - 訂單商品或金額未符合使用規定 \r\n - 非原先取得優惠券序號之帳號登入使用',
                  () => {}
                )
                return false
              }
            } else {
              this.isuseticket = false
            }
            if (
              handlePromotions(
                this.infoForm.bill_email,
                this.infoForm.is_use_member,
                order.trans_amount
              )
            ) {
              // 最後送出的結果
              const reqdata = JSON.parse(JSON.stringify(order))
              // 若有使用折扣
              if (this.isuseticket) {
                reqdata.trans_amount = JSON.parse(
                  JSON.stringify(order.finalamount)
                )
              }
              const paycheck3t = /^3tpay/g
              addStep1(reqdata)
                .then(response => {
                  if (response.data.code !== 0) {
                    if (response.data.code === 'HG5015') {
                      showNotice('請檢查您的手機號和身分證是否輸入正確')
                    } else if (response.data.code === -1) {
                      const memberInfo = self.$store.getters.memberInfo
                      // 回填上一筆訂單的資料
                      self.toggleSync()
                      const phone = memberInfo.latest_phone || ''
                      self.infoForm.bill_phone = phone
                      const email = memberInfo.latest_email || ''
                      self.infoForm.bill_email = email
                      const name = memberInfo.latest_name || ''
                      self.infoForm.bill_name = name
                    }
                  } else {
                    if (self.type === 'point' && this.channel === 'happygo') {
                      // 點加金要紀錄 若金流失敗要回扣
                      if (
                        response.data.data.orders_point_id === undefined ||
                        response.data.data.orders_point_id + '' === ''
                      ) {
                        showNotice('HappyGo點數扣除異常')
                        return
                      } else {
                        self.$store.commit(
                          'SET_PAYMENT_ORDER_ID',
                          response.data.data.orders_point_id
                        )
                      }
                    }
                    const pay = this.getPay(order.paytype_id)
                    if (pay.payment === 'newebCreditcard') {
                      self.paymentInfo = response.data.data.payment
                      self.paymentInfo.endPoint = response.data.data.endPoint
                      this.$nextTick(() => {
                        this.$refs.paymentForm.submit()
                      })
                    } else if (pay.payment === 'tscbOne') {
                      // 若是HG使用台新支付
                      if (this.channel === 'happygo' || this.channel === '3t') {
                        window.happyGoAppjscontext.call(
                          'openExternalBrowser',
                          response.data.data.endPoint,
                          () => {
                            self.$router.push({
                              path:
                                '/order/qrcode/' +
                                'none' +
                                '/' +
                                'tscb' +
                                '/' +
                                response.data.data.id
                            })
                          }
                        )
                      } else {
                        window.location.href = response.data.data.endPoint
                      }
                    } else if (pay.payment === 'sinopacCreditcard') {
                      window.location.href = response.data.data.endPoint
                    } else if (pay.payment === 'easyCard') {
                      if (response.data.data.endPoint) {
                        window.open(response.data.data.endPoint, '_self')
                      } else {
                        showNotice('系統錯誤請確認!')
                      }
                    } else if (pay.payment === 'icash') {
                      // 轉址 或 產生QRCODE
                      if (response.data.data.endPoint) {
                        try {
                          const urlParams = new URLSearchParams(
                            response.data.data.endPoint
                          )
                          let tradeToken = urlParams.get('Value')

                          if (checkPlatform() === 'android') {
                            tradeToken = encodeURIComponent(tradeToken)
                          }
                          window.iCashJSContext.call(
                            'OpenWebActivity',
                            tradeToken,
                            '3tgds'
                          )
                        } catch (error) {
                          console.log(error)
                        }
                      } else if (response.data.data.payment.qrCode) {
                        this.$router.push({
                          path:
                            '/order/qrcode/' +
                            response.data.data.payment.qrCode +
                            '/' +
                            pay.payment +
                            '/' +
                            response.data.data.id
                        })
                      }
                    } else if (pay.payment === 'cathaybk') {
                      if (pay.pay_code === 'OPW' && checkPlatform() === 'web') {
                        window.location.href = response.data.data.endPoint
                      } else if (
                        store.getters.isinapp.name === 'opapp' &&
                        response.data.data.payment.credit_num
                      ) {
                        try {
                          // call 一段檢查取得使用者當下是否有支付工具，
                          // 透過OPGetWalletStatusV2Result回傳結果(有無OPW信用卡與ICP是否有登入)與使用者有哪些OPW信用卡
                          window.OPAPPJsInterface.call(
                            'OPGetWalletStatusV2',
                            null
                          )

                          this.opappordernum =
                            response.data.data.payment.credit_num
                        } catch (error) {
                          console.log('error===>' + error)
                        }
                      }
                    } else if (paycheck3t.test(pay.payment)) {
                      if (response.data.data.payment) {
                        self.paymentInfo = response.data.data.payment
                        self.paymentInfo.endPoint = response.data.data.endPoint
                        this.$nextTick(() => {
                          this.$refs.paymentForm.submit()
                        })
                      } else if (response.data.data.endPoint) {
                        window.open(response.data.data.endPoint, '_self')
                      } else {
                        showNotice('系統錯誤請確認!')
                      }
                    } else {
                      // 下單成功
                      this.$router
                        .push({
                          path: '/order/finish',
                          query: { order_num: response.data.data.order_num }
                        })
                        .catch(() => {})
                    }
                  }
                })
                .catch(() => {})
                .finally(() => {
                  self.$store.commit('SET_LOADING', false)
                })
            } else {
              showNotice('系統提醒:資料錯誤。', function() {})
            }
          }
        } else {
          if (this.errors.items.length) {
            if (Array.isArray(this.$refs[this.errors.items[0].field])) {
              this.$refs[this.errors.items[0].field][0].focus()
            } else this.$refs[this.errors.items[0].field].focus()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';

.box-group {
  margin: 0 auto 1.5rem;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);

  .box-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    // border-bottom: 2px solid rgba($white-color, $alpha: 1);
    .box-th {
      // border-left: 2px solid rgba($main-color, $alpha: 1);
      padding: 5px 15px 5px 0px;
      white-space: nowrap;
    }

    .box-td {
      text-align: right;
      padding-left: 10px;
    }
  }
}

@media screen and (max-width: 320px) {
  .small-mobile .radiocheck.check label {
    display: inherit;
  }
}

.passengewarn {
  text-align: center;
}

.orderinit-area .order-area {
  .sub-title {
    text-align: center;
    font-size: 1.15rem;
    font-weight: bolder;
    color: rgba($main-color, $alpha: 1);
    background: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      color-stop(0.5, rgba($font-color, $alpha: 0.5)),
      color-stop(0.5, transparent),
      to(transparent)
    );
    background-repeat: repeat-x;
    background-position: center center;
    -webkit-background-size: 2px;
    display: block;
    margin: 0 auto 0.5rem;

    span {
      padding: 0 35px;
      background-color: $white-color;
    }
  }

  .passengerlist {
    text-align: start;
    padding-top: 1rem;
    font-size: 1.15rem;
    font-weight: bolder;
    color: rgba($font-color, $alpha: 1);
    text-align: center;
  }

  .topline {
    border-top: 1px rgb(214, 214, 214) solid;
  }

  a {
    color: rgba($main-color, $alpha: 1);
  }

  a:hover {
    color: rgba($main-color, $alpha: 1);
    text-decoration: underline;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.main {
  p {
    margin: 0 0 0.5rem;
    padding: 0;
  }

  dt {
    color: $main-color;
    margin-bottom: 0.35rem;
  }

  dd {
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
  }

  dd span {
    color: $sec-color;
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    .price {
      color: $notice-color;
    }
  }
}

.checkcredicbarea {
  label {
    display: inline-block;
  }
}
</style>
